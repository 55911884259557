<template>
  <v-app id="inspire">
    <v-overlay :value="download" style="z-index:9999">
      <div class="text-center">
        <v-row
          align-content="center"
          class="fill-height"
          justify="center"
        >
          <v-col
            class="text-subtitle-1 text-center"
            cols="12"
          >
            Carregando gabarito, aguarde!
          </v-col>
          <v-col cols="6">
            <v-progress-linear
              color="white accent-4"
              height="6"
              indeterminate
              rounded
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
    </v-overlay>
    <v-navigation-drawer v-model="drawer" app width="400">
      <v-sheet class="pa-4 d-flex align-center tw-flex-wrap" color="grey lighten-4">
        <v-btn color="primary" @click="disableBackButton = true" :disabled="disableBackButton" outlined :to="`/exam/application/view/${this.$route.params.id}/${this.$route.params.application}`">Voltar</v-btn>
        <div class="text-h6 ml-3">{{ application.data.name }}</div>
       <v-row class="mt-6">
        <v-col cols="10">
          <v-text-field
            @input="onSearch($event)"
            append-icon="mdi-magnify"
            label="Pesquisar alunos"
            outlined
            dense
            background-color="transparent"
            ide-details
          />
        </v-col>
        <v-col cols="2">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="20">mdi-filter</v-icon>
                <p style="color: rgb(3, 89, 248);font-size: x-small;">({{ getFiltersCount }})</p>
              </v-btn>
            </template>

            <v-card style="padding: 16px;" width="400">
              <v-card-title
                class="flex flex-col items-center justify-end">
                <v-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="20">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <button style="color: rgb(3, 89, 248);" @click="clearFilters()">Limpar filtros</button>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <AutoComplete
                      v-model="schools.selected_group"
                      :items="schools.items"
                      :disabled="schools.disabled"
                      :loading="schools.loading"
                      label="Escolas"
                      :multiple="true"
                      chips
                    />
                  </v-col>
                  <v-col cols="12">
                    <AutoComplete
                      v-model="courses.selected_group"
                      :items="courses.items"
                      :loading="courses.loading"
                      :disabled="courses.disabled"
                      :multiple="true"
                      chips
                      label="Turmas"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions class="flex items-center justify-end">
                <v-btn
                  text
                  @click="menu = false"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="setFilterActive()"
                >
                  Aplicar filtros
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-col>
       </v-row>
      </v-sheet>
      <v-divider></v-divider>
      <v-list three-line>
        <v-list-item-group ref="menu" v-model="activeStudentMenu" color="primary">
          <div v-for="(item,index) in items" :key="item.id">
            <div>
              <v-list-item @click="selectStudent(item, index)">
                <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="item.email"></v-list-item-subtitle>
                  <template>
                    <div class="d-flex tw-justify-start tw-items-center tw-overflow-x-auto">
                      <v-chip v-for="i in item.courses" :key="i.id" class="mr-1 mb-1" pill x-small>
                        <v-avatar left>
                          <v-img
                            :src="`https://gravatar.com/avatar/${md5(i.id)}?s=80&d=identicon&r=x`"
                          ></v-img>
                        </v-avatar>
                        {{ i.name }}
                      </v-chip>
                      <!-- </div> -->
                    </div>
                  </template>
                </v-list-item-content>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                            v-on="on"> {{ item.statusRaw === 'NOT_STARTED' ? 'mdi-file-outline' : 'mdi-file' }}
                    </v-icon>
                  </template>
                  <span>{{
                      item.statusRaw === 'NOT_STARTED' ? 'Gabarito não preenchido' : 'Gabarito preenchido'
                    }}</span>
                </v-tooltip>
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </div>
          <div
            v-intersect="{
              handler: getStudentsScroll,
              options: {
                rootMargin: '0px',
                threshold: 1.0,
              },
            }"
          >
            <v-list-item-content v-if="getStudentsIsfetching">
              <v-list-item-title class="px-4">
                <v-progress-circular
                  class="mr-3"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
                Buscando alunos ..
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else-if="getAllStudents && items.length > 0">
              <v-list-item-title class="px-4">Todos os alunos já estão listados</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else-if="items.length === 0">
              <v-list-item-title class="px-4">Nenhum aluno encontrado</v-list-item-title>
            </v-list-item-content>
            <v-list-item v-else @click="getStudentsScroll">
              <v-list-item-content>
                <v-list-item-title>Mostrar mais alunos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-container :class="$vuetify.breakpoint.mobile ? 'px-0' : 'px-6'" fluid>
        <v-row>
          <div class="ml-4 mt-2">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
          </div>
        </v-row>
        <div>
          <!-- <v-btn color="pink" dark @click.stop="drawer = !drawer"> Menu </v-btn> -->
          <v-responsive
            v-if="!activeStudent"
            class="d-flex align-center text-center pa-2"
            height="100vh"
          >
            <v-card class="mx-auto" max-width="336">
              <v-card-text>Selecione um aluno para exibir o gabarito</v-card-text>
            </v-card>
          </v-responsive>
          <template v-else>
            <v-row>
              <v-col :key="key" cols="12">
                <v-card>
                  <!-- <v-subheader> Gabarito </v-subheader> -->
                  <div class="d-flex tw-flex-wrap">
                    <div class="tw-w-full">

                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img :src="activeStudent.avatar"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title v-html="activeStudent.name"></v-list-item-title>
                          <v-list-item-subtitle v-html="activeStudent.email"></v-list-item-subtitle>
                          <template>
                            <div class="d-flex tw-justify-start tw-items-center tw-flex-wrap">
                              <v-chip
                                v-for="i in activeStudent.courses"
                                :key="i.id"
                                class="mr-1 mb-1"
                                pill
                                x-small
                              >
                                <v-avatar left>
                                  <v-img
                                    :src="`https://gravatar.com/avatar/${md5(i.id)}?s=80&d=identicon&r=x`"
                                  ></v-img>
                                </v-avatar>
                                {{ i.name }}
                              </v-chip>
                              <!-- </div> -->
                            </div>
                          </template>
                        </v-list-item-content>

                      </v-list-item>
                    </div>

                    <div class="my-4 d-flex tw-w-full">
                      <v-spacer/>

                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip color="grey darken-2 mr-4"
                                  outlined v-bind="attrs" v-on="on">
                            <v-icon color="grey darken-2">mdi-forum</v-icon>
                            <span class="text-h6 ">
                         {{ count }}/{{ countQuestion }}
                        </span>
                          </v-chip>
                        </template>
                        <span>Quantidade de questões preenchidas</span>
                      </v-tooltip>

                    </div>
                  </div>
                  <v-divider></v-divider>

                  <!-- <v-subheader> Gabarito </v-subheader> -->
                  <answer-key
                    :marker-application="application.data.marker"
                    :student="activeStudent"
                    :studentIndex="activeStudentIndex"
                    @count="count = $event"
                    @countQuestion="countQuestion = $event"
                    @studentIndex="items[$event].statusRaw = 'true'"
                  />
                </v-card>

                <v-card></v-card>
              </v-col>
            </v-row>

          </template>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { format, parseISO } from 'date-fns'
import md5 from '../../libs/md5'
import { getStatusColor, getStatusName } from '@/utils/status'
import {
  isSuperAdmin,
  getSchoolIds,
  getCourseIds,
  hasOrganizationLevel,
  hasSchoolLevel,
  getSchoolCourseIds
} from '@/utils/helpers'
import AutoComplete from '@/components/select/AutoComplete.vue'
import collect from 'collect.js'

// import questions from './questions'
export default {
  components: {
    AutoComplete
  },
  data: () => ({
    menu: false,
    download: true,
    count: 0,
    countQuestion: 0,
    getStudentsIsfetching: false,
    drawer: null,
    activeStudent: null,
    activeStudentIndex: null,
    questions: [],
    questionsFetch: false,
    paginationQuestion: null,
    questionsStudent: null,
    activeStudentMenu: null,
    getAllStudents: false,
    key: 0,
    id: '',
    application: [],
    applicationFetch: false,
    disableBackButton: false,
    pagination: {
      page: 1,
      itemsPerPage: 40,
      prev_page_url: false,
      next_page_url: false
    },
    items: [],
    links: [
      ['mdi-inbox-arrow-down', 'Inbox'],
      ['mdi-send', 'Send'],
      ['mdi-delete', 'Trash'],
      ['mdi-alert-octagon', 'Spam']
    ],
    format,
    parseISO,
    debouceId: null,
    schools: {
      items: [],
      selected_group: [],
      loading: false,
      disabled: false,
      pagination: {}
    },
    courses: {
      items: [],
      selected_group: [],
      loading: false,
      disabled: false,
      pagination: {}
    },
    isFiltering: false
  }),
  computed: {
    getFiltersCount () {
      return this.schools.selected_group.length + this.courses.selected_group.length
    }
  },
  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')

    if (!this.id) {
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Você precisa selecionar uma organização'
      })
    }

    const response = await this.$axios.post('/auth/me')
    this.$store.commit('setUserMe', response.data)

    this.getPeople()
    this.getApplication()
  },
  methods: {
    getStatusName,
    getStatusColor,
    md5,
    getCourseIds,
    getSchoolIds,
    isSuperAdmin,
    getSchoolCourseIds,
    hasSchoolLevel,
    hasOrganizationLevel,
    async getStudentsScroll () {
      if (this.getStudentsIsfetching || this.getAllStudents) {
        return
      }
      await this.getPeople()
    },
    async selectStudent (item, index) {
      this.count = 0
      if (this.$vuetify.breakpoint.mobile) this.drawer = !this.drawer
      this.questionsStudent = JSON.parse(JSON.stringify(this.questions))
      this.activeStudent = item
      this.activeStudentIndex = index
      this.key++
    },
    buildQuery () {
      return `?page=${this.pagination.page}&per_page=${this.pagination.itemsPerPage}`
    },
    openDialog () {
      this.dialog.show = true
    },
    formatSeconds (sec) {
      if (!sec) return 0
      const date = new Date(0)
      date.setSeconds(sec) // specify value for SECONDS here
      return date.toISOString().substr(11, 8)
    },
    formatDate (d) {
      if (!d) return 'Não iniciado'
      return format(parseISO(d), 'dd/MM/yyyy HH:mm')
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        if (value) {
          const obj = {
            ...value,
            avatar: value.student_avatar,
            name: value.student_name,
            email: value.student_email,
            idUser: value.student_id,
            id: value.id,
            statusRaw: value.status,
            status: {
              name: this.getStatusName(value.status),
              color: this.getStatusColor(value.status)
            },
            started_at: this.formatDate(value.started_at),
            ended_at: this.formatDate(value.ended_at),
            grade: value.grade ? value.grade : 0,
            time_spent: this.formatSeconds(value.time_spent),
            url:
              value.status === 'NOT_STARTED'
                ? `/user/profile/${value.student_id}`
                : `/exam/${this.$route.params.id}/application/${this.$route.params.application}/resolution/${value.id}`,
            // roles: value.data.roles,
            isSelected: false
          }
          arr.push(obj)
        }
      })
      return arr
    },
    async getApplication () {
      this.$axios.get(
        `organizations/${this.id}/exams/${this.$route.params.id}/applications/${this.$route.params.application}`
      ).then(res => {
        this.application = res.data
        this.mountSchoolsFilters(res.data.data.courses_group_schools)
        this.mountCoursesFilters(res.data.data.courses_group)
      })
        .catch(() => {
          const msg = 'Erro na conexão, tente novamente'
          this.$store.dispatch('alert', { color: 'red', msg })
        })
        .finally(() => {
          this.download = false
          this.applicationFetch = true
        })
    },
    onSearch (value) {
      this.getStudentsIsfetching = true
      this.pagination.page = 1
      this.items = []
      this.getAllStudents = false
      clearTimeout(this.debouceId)
      this.debouceId = setTimeout(() => {
        this.getStudentsIsfetching = false
        this.getPeople(value)
      }, 1500)
    },
    async getPeople (search = '') {
      this.getStudentsIsfetching = true

      const payload = {
        ...(search ? { search } : {}),
        ...(!this.hasOrganizationLevel() && !this.isSuperAdmin() && this.getCourseIds().length ? { course_ids: this.getCourseIds() } : {}),
        ...(!this.hasOrganizationLevel() && !this.isSuperAdmin() && this.getSchoolIds().length ? { school_ids: this.getSchoolIds() } : {})
      }

      const payloadFilters = {
        ...(search ? { search } : {}),
        ...(this.getSchoolsFilters().length ? { school_ids: this.getSchoolsFilters() } : {}),
        ...(this.getCoursesFilters().length ? { course_ids: this.getCoursesFilters() } : {})
      }

      try {
        await this.$axios
          .post(
            `/organizations/${this.id}` +
            `/lists/exams-applications/${this.$route.params.application}` +
            `/students${this.buildQuery()}`, this.isFiltering ? payloadFilters : payload)
          .then(response => {
            if (response.data.items.length > 0) {
              this.items = [
                ...this.items,
                ...this.formatPeoples(response.data.items)
              ]
              const pagination = response.data.pagination
              this.pagination = {
                ...this.pagination,
                total: pagination.total,
                page: pagination.current_page + 1,
                itemsPerPage: pagination.per_page
              }
            } else if (!response.data.pagination.next_page_url) {
              this.getAllStudents = true
            }
          })
      } catch (error) {
      } finally {
        this.getStudentsIsfetching = false
      }
    },
    mountSchoolsFilters (schools) {
      if (!schools) {
        return
      }

      if (!this.isSuperAdmin() && !this.hasOrganizationLevel()) {
        schools = schools.filter(school => {
          return [
            ...this.getSchoolIds(),
            ...this.getSchoolCourseIds()
          ].includes(school.id)
        })
      }

      this.schools.items = schools.map(school => {
        return {
          value: school.id,
          name: school.name
        }
      })
    },
    mountCoursesFilters (courses) {
      if (!courses) {
        return
      }

      if (!this.isSuperAdmin() && !this.hasOrganizationLevel() && !this.hasSchoolLevel()) {
        courses = courses.filter(course => {
          return this.getCourseIds().includes(course.id)
        })
      }

      if (this.hasSchoolLevel()) {
        courses = courses.filter(course => {
          const schoolId = course?.ref?.split('/')[3]
          return this.getSchoolIds().includes(schoolId)
        })
      }

      this.courses.items = courses.map(course => {
        return {
          value: course.id,
          name: course.name,
          subtitle: this.getSchoolNamebyCourseRef(course.ref)
        }
      })
    },
    getSchoolNamebyCourseRef (courseRef) {
      const schoolId = courseRef?.split('/')[3]
      const school = this.schools.items.find(school => school.value === schoolId)
      return school?.name
    },
    clearFilters () {
      this.menu = false
      if (!this.isFiltering) {
        return
      }
      this.schools.selected_group = []
      this.courses.selected_group = []
      this.isFiltering = false
      this.onSearch()
    },
    setFilterActive () {
      this.menu = false
      this.isFiltering = this.schools.selected_group.length > 0 || this.courses.selected_group.length > 0
      if (this.isFiltering) {
        this.onSearch()
      }
    },
    getSchoolsFilters () {
      return collect(this.schools.selected_group)
        .pluck('value')
        .toArray()
    },
    getCoursesFilters () {
      return collect(this.courses.selected_group)
        .pluck('value')
        .toArray()
    }
  }
}
</script>
